import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  FormControl,
  Input,
  FormHelperText,
  Button,
} from '@material-ui/core';
import { Form, Formik, Field, FieldProps, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { KeyboardArrowLeft } from '@material-ui/icons';
import MobileLayout from '../MobileLayout';
import OrangeAlien from '../asset/characters/OrangeAlien.png';
import arrowRightWhite from '../asset/icons/rightarrow.png';
import { useNavigate } from 'react-router';
import { forgotPassword } from './RegisterService'; // Import the forgotPassword function
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  backButton: {
    color: 'black',
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: 'black',
    textAlign: 'center',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  formHeader: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(5),
    width: '100%',
    textAlign: 'left',
  },
  form: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    paddingBottom: theme.spacing(10), // Adjust padding to leave space for the button at the bottom
  },
  formControl: {
    margin: theme.spacing(1),
    width: '85%',
  },
  inputRoot: {
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0 0 1px black',
    padding: '0 20px',
    height: '3rem',
  },
  submitButton: {
    width: '80%',
    position: 'absolute',
    bottom: theme.spacing(10),
    backgroundColor: '#EB6608',
    color: 'white',
    height: '3rem',
    borderRadius: '10px',
    fontSize: '1.0rem',
    [theme.breakpoints.up('sm')]: {
      width: '25%',
    },
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: theme.spacing(2),
  },
}));

interface ForgotPasswordValues {
  email: string;
}

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [resetLinkSent, setResetLinkSent] = useState(false);
  const initialValues: ForgotPasswordValues = {
    email: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
  });

  const handleSubmit = async (
    values: ForgotPasswordValues,
    { setSubmitting }: FormikHelpers<ForgotPasswordValues>
  ) => {
    try {
      await forgotPassword(values.email);
      setResetLinkSent(true);
    } catch (error) {
      console.error('Error sending reset password email:', error);
    } finally {
      setSubmitting(false);
    }
  };
  const [t, i18n] = useTranslation("global");
  
  return (
    <MobileLayout>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" aria-label="back" className={classes.backButton} onClick={() => navigate('/sign-in')}>
            <KeyboardArrowLeft />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
          {t('forgotPass.frgtPass')}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.imageContainer}>
        <img src={OrangeAlien} alt="Forgot Password" className={classes.image} />
      </div>
      {resetLinkSent ? (
        <>
          <Typography variant="body1" className={classes.formHeader}>
          {t('forgotPass.buttTxt1')}
          </Typography>
          <div className={classes.buttonContainer}>
            <Button
              style={{ textTransform: 'none' }}
              type="submit"
              variant="contained"
              className={classes.submitButton}
              onClick={() => navigate('/sign-in')}
            >
              {t('forgotPass.buttTxt2')}
            </Button>
          </div>
        </>
      ) : (
        <>
          <Typography variant="body1" className={classes.formHeader}>
            {t('forgotPass.title')}
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, handleChange, setFieldTouched, isSubmitting }) => (
              <Form className={classes.form}>
                <FormControl className={classes.formControl}>
                  <Field name="email">
                    {({ field }: FieldProps) => (
                      <Input
                        {...field}
                        placeholder="name@host.com"
                        onBlur={() => setFieldTouched('email', true)}
                        onFocus={() => setFieldTouched('email', true)}
                        classes={{ root: classes.inputRoot }}
                        disableUnderline
                      />
                    )}
                  </Field>
                  {errors.email && touched.email && (
                    <FormHelperText error>{errors.email}</FormHelperText>
                  )}
                </FormControl>
                <Button
                  style={{ textTransform: 'none' }}
                  type="submit"
                  variant="contained"
                  className={classes.submitButton}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? t('forgotPass.buttTxt32') : t('forgotPass.buttTxt31')}
                </Button>
              </Form>
            )}
          </Formik>
        </>
      )}
    </MobileLayout>
  );
};

export default ForgotPasswordPage;