import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function OutlinedCard({message}:any) {
  return (
    <Box sx={{ minWidth: 275,display: 'flex' ,justifyContent:'center' ,flexDirection:'column'}}>
    
      <Typography variant="h5" component="div" sx={{display: 'flex' ,justifyContent:'center'}}>
       {message}
      </Typography>
    
    </Box>
  );
}
