import React, { useState, useEffect } from "react";
import MobileLayout from "../MobileLayout";
import logo from "../asset/logo/LOGO_ORANGE.svg";
// import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from "react-router";
import { AppBar, Box, makeStyles, Toolbar, Typography } from "@material-ui/core";
import Logo from "../asset/logo/LOGO_ORANGE.svg";
import breeferPlayButton from "../asset/icons/breeferPlayButton.png";
import { useTranslation } from "react-i18next";
import "../App.css";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import mixpanel from "../mixpanel/mixpanelWrapper";

interface Question {
  question: string;
  options: string[];
  answer: number;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    cursor: "pointer", // Adjust this as needed
  },
  appBar: {
    position: "relative",
    backgroundColor: "transparent",
    boxShadow: "none",
  },

  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "85%",
    display: "flex",
    justifyContent: "center",
    alignItems: "end",

    zIndex: 2,
  },
  hidden: {
    display: "none",
  },
}));

const QuizInstruction = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [t, i18n] = useTranslation("global");
  const userId: any = localStorage.getItem("InvitedUserId");
  const [isTracked, setIsTracked] = useState(false);
  const breefDetails = useSelector((state: RootState) => state.score.breefDetails);
  const questions: Question[] = breefDetails.questions;

  useEffect(() => {
    if (!isTracked) {
      mixpanel.track("Quiz Info page viewed", {
        breef_id: breefDetails?.id,
        tenant_id: breefDetails?.tenantId.id,
        user_id: userId,
        number_of_qustions: breefDetails?.questions.length,
        start_date: breefDetails?.period,
        expiry_date: breefDetails?.expiry,
        video_url: breefDetails?.videoUrl,
        current_page: "quiz-info",
        game_name: breefDetails?.gameId.gameName,
      });
      setIsTracked(true);
    }
  }, [isTracked]);

  return (
    <MobileLayout>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div style={{ margin: "0% 10%", paddingLeft: "2%", height: "70%" }}>
          <div className="container quiz-info" style={{ lineHeight: 1, textAlign: "left", color: "#000000"}}>
            <div style={{ color: "#ff6a20", fontWeight: "bold" }}>{t("quizInfo.disc1")}</div>
            <div style={{ color: "#ff6a20", fontWeight: "bold" }}>{t("quizInfo.disc2")}</div>
            <div style={{ color: "#ff6a20", fontWeight: "bold" }}>{t("quizInfo.disc3")}</div>
            {t("quizInfo.disc4")}
          </div>
        </div>
        <div className={classes.overlay}>
          <img
            src={breeferPlayButton}
            alt="Replay Button"
            style={{ cursor: "pointer" }}
            height={"90px"}
            onClick={() => {
              navigate("/quiz");
              mixpanel.track("Quiz Info page viewed", {
                breef_id: breefDetails?.id,
                tenant_id: breefDetails?.tenantId.id,
                user_id: userId,
                number_of_qustions: breefDetails?.questions.length,
                start_date: breefDetails?.period,
                expiry_date: breefDetails?.expiry,
                video_url: breefDetails?.videoUrl,
                current_page: "quiz-info",
                game_name: breefDetails?.gameId.gameName,
              });
            }}
          />
        </div>
      </div>

      {/* <Box
        style={{ position: "absolute", bottom: "7rem", left: "50%", transform: "translateX(-50%)" }}
      > */}
      {/* <img src={breeferButton} alt="Breefer Button" onClick={nextButtonHandle} style={{ cursor: 'pointer' }} /> */}
      {/* <img
          src={introductionAlien}
          onClick={nextButtonHandle}
          alt="Blue Alien"
          style={{ position: "absolute", top: -170, right: -150, width: "170px", height: "170px" }}
        />
      </Box> */}
    </MobileLayout>
  );
};

export default QuizInstruction;
