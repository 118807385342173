import axios from "axios";

interface QuizChance {
  index: number;
  number: number;
}

interface BreefReportPayload {
  quizScore: number;
  videoWatchTime: string;
  breefScore: number;
  userId: string;
  user_id: string;
  breefId: string;
  teamId: string;
  tenantId: string;
  quizChances: QuizChance[];
}
const BreefReportService = {
  async createBreefReport(
    quizScore: number,
    videoDuration: number,
    breefScore: number,
    quizChances: QuizChance[],
    setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>,
    setSnackbarSeverity: React.Dispatch<React.SetStateAction<"success" | "error">>,
    setSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>
  ): Promise<void> {
    const videoDurationString = videoDuration.toString();
    const breefId: any = localStorage.getItem("breefId");
    const invitedUserId: any = localStorage.getItem("InvitedUserId");
    //const existingUserId: any = localStorage.getItem("ExistingUserId");
    const tenantId: any = localStorage.getItem("tenantId");
    const teamId: any = localStorage.getItem("teamId");

    const payload: BreefReportPayload = {
      quizScore,
      videoWatchTime: videoDurationString || "",
      breefScore: breefScore || 0,
      userId: invitedUserId,
      user_id: "none",
      breefId,
      teamId: teamId,
      tenantId: tenantId,
      quizChances,
    };

    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/v1/Breefs/breef-report`, payload);
      setSnackbarMessage("Breef quiz submitted successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error: any) {
      console.error("Error creating breef report:", error);
      if (error.response && error.response.status === 409) {
        setSnackbarMessage("You have already submitted the breef");
      } else {
        setSnackbarMessage("An error occurred while creating the breef report");
      }
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  },

  async updateBreefStatus(): Promise<void> {
    const breefId: any = localStorage.getItem("breefId");
    const invitedUserId: any = localStorage.getItem("InvitedUserId");
    const existingUserId: any = localStorage.getItem("ExistingUserId");
    let userId = "";
    if (invitedUserId) {
      userId = invitedUserId;
    } else {
      userId = existingUserId;
    }
    if (breefId && userId) {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/v1/Breefs/breef-invite-status/${userId}/${breefId}`
        );
        console.log(response.data);
      } catch (error) {
        console.error("Error updating breef status:", error);
      }
    } else {
      console.error("Missing breefId or userId in local storage");
    }
  },
  async updateBreefTotalScore(
    totalScore: number,
    setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>,
    setSnackbarSeverity: React.Dispatch<React.SetStateAction<"success" | "error">>,
    setSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>
  ): Promise<void> {
    const breefId: any = localStorage.getItem("breefId");
    const invitedUserId: any = localStorage.getItem("InvitedUserId");

    if (breefId && invitedUserId) {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/v1/Breefs/breef-total-score/${invitedUserId}/${breefId}`,
          { totalScore }
        );
        setSnackbarMessage("Breef completed successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        console.log(response.data);
      } catch (error) {
        console.error("Error updating breef status:", error);
      }
    } else {
      console.error("Missing breefId or userId in local storage");
    }
  },

  async getBreefCountByUserId(): Promise<any> {
    const invitedUserId: any = localStorage.getItem("InvitedUserId");
    const existingUserId: any = localStorage.getItem("ExistingUserId");
    let userId = "";
    if (invitedUserId) {
      userId = invitedUserId;
    } else {
      userId = existingUserId;
    }
    if (userId) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/v1/breefs/breefs/user/${userId}/noOfBreefs`
        );

        console.log(response.data);
        return response.data;
      } catch (error) {
        console.error("Breef fetching:", error);
        return null;
      }
    } else {
      console.error("Missing breefId or userId in local storage");
      return null;
    }
  },
};

export default BreefReportService;
