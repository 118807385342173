import { useState, useEffect } from "react";
import { Typography, Box, LinearProgress, makeStyles, AppBar, Toolbar } from "@material-ui/core";
import MobileLayout from "../MobileLayout";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setScore } from "./slice";
import { RootState } from "../redux/store";
import Logo from "../asset/logo/LOGO_ORANGE.svg";
import mixpanel from "../mixpanel/mixpanelWrapper";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    padding: "3%",
  },
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5%",
  },
  gameSpace: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70vh",
    boxShadow: "0px 0px 5px rgba(136, 136, 136, 0.6)",
    borderRadius: "10px",
    marginTop: "5%",
    marginBottom: "10%",
    backgroundColor: "white",
    cursor: "pointer",
  },
  progressContainer: {
    width: "100%",
    
    cursor: "pointer",
  },
  linearProgress: {
    border: "1px solid rgba(136, 136, 136, 0.6)",
    margin:"0% 10%",
    borderRadius: "5px",
    backgroundColor: "white",
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#EC6608",
      borderRadius: "5px",
    },
    height: "8px",
  },
  points: { 
    marginTop: "5%",
    fontSize:"25px",
  },
  appBar: {
    position: "relative",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
}));

const GameScenePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId: any = localStorage.getItem("InvitedUserId");
  const classes = useStyles();

  const [isTracked, setIsTracked] = useState(false);

  useEffect(() => {
    if (!isTracked) {
      mixpanel.track("Game Scene Viewed", {
        breef_id: breefDetails?.id,
        tenant_id: breefDetails?.tenantId.id,
        user_id: userId,
        number_of_qustions: breefDetails?.questions.length,
        start_date: breefDetails?.period,
        expiry_date: breefDetails?.expiry,
        video_url: breefDetails?.videoUrl,
        current_page: "game-scene",
        quiz_score:quizScore,
        game_name: breefDetails?.gameId.gameName,
      });
      setIsTracked(true);
    }
  }, [isTracked]);

  const quizScore = useSelector((state: RootState) => state.score.quizScore);
  const breefDetails = useSelector((state: RootState) => state.score.breefDetails);
  const duration = useSelector((state: RootState) => state.score.duration);
  console.log("INFO :", quizScore, duration, breefDetails);

  const [progress, setProgress] = useState(0);
  const [points, setPoints] = useState(0);

  useEffect(() => {
    const handleMessage = (event: any) => {
      console.log("Event is :", event.origin);
      if (
        event.origin !== "https://test-app.breefer.dk" &&
        event.origin !== "http://localhost:5000"
      ) {
        console.log("handle message called");
        const { score } = event.data;
        setPoints(score * 100);
        dispatch(setScore(score * 100));
        setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 10));

        console.log("Received score from game:", score);
        setTimeout(() => {
          console.log("timeout called");
          mixpanel.track("Game finished", {
            breef_id: breefDetails?.id,
            tenant_id: breefDetails?.tenantId.id,
            user_id: userId,
            number_of_qustions: breefDetails?.questions.length,
            start_date: breefDetails?.period,
            expiry_date: breefDetails?.expiry,
            video_url: breefDetails?.videoUrl,
            current_page: "game-scene",
            game_score: points,
            breef_score: quizScore,
            total_score: points + quizScore,
            game_name: breefDetails?.gameId.gameName,
          });
          navigate("/game-result", { state: { points, quizScore, duration, breefDetails } });
        }, 2000);
      }
    };
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <MobileLayout>
      <div className={classes.container}>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>{/* <img src={Logo} alt="logo" height="80 rem" /> */}</Toolbar>
        </AppBar>
        {/* <div className={classes.header}>
          <Typography variant="h6" align="center">
            {breefDetails.gameId.gameName}
          </Typography>
        </div> */}

        <div className={classes.gameSpace}>
          <iframe
            src={breefDetails.gameId.gameCode}
            width="100%"
            height="100%"
            style={{ border: "none" }}
            title="Game Iframe"
          />
        </div>
        <Box className={classes.progressContainer}>
          <LinearProgress
            variant="determinate"
            value={progress}
            className={classes.linearProgress}
            style={{ boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.6)" }}
          />
        </Box>
        <div className={classes.points}>
          <Typography variant="body1" align="center" style={{fontSize:"25px"}}>
            {isNaN(points) ? 0 : points + quizScore} Points
          </Typography>
        </div>
      </div>
    </MobileLayout>
  );
};

export default GameScenePage;
