import React from 'react';
import MobileLayout from '../MobileLayout';
import logo from '../asset/logo/LOGO_ORANGE.svg';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router';
import BasicCard from '../home-section/BasicCard';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40vh',
    cursor: 'pointer' // Adjust this as needed
  },
}));

export const CheckBreefExpiryOrSubmitted = () => {
    const navigate = useNavigate();
  const classes = useStyles();

  return (
    <MobileLayout>
      <div className={classes.container} onClick={()=>navigate('/sign-up')}>
        <img className='' height={"40%"} src={logo} alt="logo" />
      </div>
      <BasicCard message={"You can not access this breef"}  />
    </MobileLayout>
  );
};


