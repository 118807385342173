import mixpanel from "mixpanel-browser";
mixpanel.init("cdc81260ce4a4760c3b52ef4fd214ae1", {
  debug: true,
  ignore_dnt: true,
  track_pageview: true,
  persistence: "localStorage",
});

let actions = {
  identify: (id: string) => {
     mixpanel.identify(id);
  },
  alias: (id: string) => {
     mixpanel.alias(id);
  },
  track: (name: string, props: any) => {
     mixpanel.track(name, props);
  },
  reset: () => {
   mixpanel.reset();
},
  people: {
    set: (props: any) => {
       mixpanel.people.set(props);
    },
  },
  trackpageView: (pageName: string) => {
     mixpanel.track_pageview({"page": pageName});
  },
};

export default actions;
