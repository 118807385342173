import axios from "axios";

export const getUserRank = async () => {
  const accessToken = localStorage.getItem('accessToken');
  const user_id = localStorage.getItem('userId') || '';
  if (!accessToken) {
    throw new Error('Access token not found');
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/v1/Breefs/rank/${user_id}`);
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || 'Failed to fetch pending breefs');
    } else if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error('An unknown error occurred');
    }
  }
};
  