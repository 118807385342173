export const getUserOrInvitedUser =  (existingUserId: boolean) => {
    try {
        let userType = "";
        console.log("existingUserId: ", existingUserId);
        if(existingUserId){
            userType = "existingUser";
            return {data:"Please Login",userType};
        }
        else{
            // if (!isValidObjectId(existingUserId)) {
            //     throw new Error("Invalid userId format");
            // }
            // const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/v1/users/get-invited-user/${existingUserId}`);
            userType = "NewUser";
            // return { data: response.data, userType };
            return {data: "Please signup", userType};
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
}; 