import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Avatar,
  IconButton,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Link,
  Button,
} from "@material-ui/core";
import { Formik, Field, FieldProps, Form } from "formik";
import * as Yup from "yup";
import { KeyboardArrowLeft } from "@material-ui/icons";
import MobileLayout from "../MobileLayout";
import profilePic from "../asset/characters/profile.png";
import { useNavigate } from "react-router";
import { getUserDetails, updateUserDetails } from "./ProfileService";
import logout from "../asset/icons/logout.png";
import { setFirstName, setLastName, setEmail, setPhone } from "../login/slice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { Alert } from "@mui/material";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import { useTranslation } from "react-i18next";
import { signout } from "../login/RegisterService";
import mixpanel from "../mixpanel/mixpanelWrapper";

const ProfilePage = () => {
  const smFname = useSelector((state: RootState) => state.onboard.firstname);
  const smLname = useSelector((state: RootState) => state.onboard.lastname);
  const smEmail = useSelector((state: RootState) => state.onboard.email);
  const smPhone = useSelector((state: RootState) => state.onboard.phone);
  const smUserID = useSelector((state: RootState) => state.onboard.userID);
  console.log("SM: ", smFname, smLname, smEmail, smPhone, smUserID);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [isTracked, setIsTracked] = useState(false);

  const [activeField, setActiveField] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("error");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    if (!isTracked) {
      mixpanel.track("Profile viewed", {
        current_page: "profile",
      });
      setIsTracked(true);
    }
  }, [isTracked]);

  const handleClose = (
    event: Event | React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const handleLogout = () => {
    signout(); // Call the signout service
    
    mixpanel.track("Signout clicked", {
      current_page: "profile",
    });
    mixpanel.reset();
    navigate("/sign-in"); // Redirect to the login page
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const userDetails = await getUserDetails();
        setInitialValues({
          name: userDetails.firstName + " " + userDetails.lastName,
          email: userDetails.email,
          phone: userDetails.phoneNumber,
        });
      } catch (error) {
        console.error("Error fetching user details", error);
        setErrorMessage("Error fetching user details");
        setSnackbarOpen(true);
      }
    };

    fetchUserDetails();
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    phone: Yup.string().required("Required"),
  });

  const handleEditPicture = () => {
    console.log("edit picture button clicked");
    // Handle edit picture button click
  };

  const handleChangePassword = () => {
    mixpanel.track("Change password clicked", {
      current_page: "profile",
    });
    navigate("/change-password");
    // Handle change password link click
  };

  const handleFocus = (fieldName: string) => {
    setActiveField(fieldName);
  };

  const handleBlur = (
    fieldName: string,
    setFieldTouched: (fieldName: string, touched?: boolean, shouldValidate?: boolean) => void
  ) => {
    setFieldTouched(fieldName, true);
    setActiveField("");
  };
  const handleSubmit = async (values: any, actions: any) => {
    console.log(values.name.split(" ")[0]);
    console.log(values.name.split(" ")[1]);

    try {
      const resp = await updateUserDetails({
        firstName: values.name.split(" ")[0],
        lastName: values.name.split(" ")[1],
        phoneNumber: values.phone,
      });
      console.log("Resp: ", resp);
      dispatch(setFirstName(resp.data.firstName));
      dispatch(setLastName(resp.data.lastName));
      dispatch(setPhone(resp.data.phoneNumber));
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <MobileLayout>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            aria-label="back"
            className={classes.backButton}
            onClick={() => navigate("/home")}
          >
            <KeyboardArrowLeft />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t("profileSection.profile")}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.profilePicture}>
        <Avatar
          alt="Profile Picture"
          src={profilePic}
          className={classes.avatar}
          onClick={handleEditPicture}
        />
      </div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, handleChange, setFieldValue, setFieldTouched }) => (
          <Form className={classes.form}>
            <FormControl className={classes.formControl}>
              <InputLabel
                htmlFor="name"
                className={activeField === "name" ? classes.activeInputLabel : ""}
              >
                {t("profileSection.name")}
              </InputLabel>
              <Field name="name">
                {({ field }: FieldProps) => (
                  <Input
                    {...field}
                    onBlur={() => handleBlur("name", setFieldTouched)}
                    onFocus={() => handleFocus("name")}
                    classes={{ root: classes.inputRoot }}
                    disableUnderline
                  />
                )}
              </Field>
              {errors.name && touched.name && <FormHelperText error>{errors.name}</FormHelperText>}
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel
                htmlFor="email"
                className={activeField === "email" ? classes.activeInputLabel : ""}
              >
                {t("profileSection.email")}
              </InputLabel>
              <Field name="email">
                {({ field }: FieldProps) => (
                  <Input
                    {...field}
                    onBlur={() => handleBlur("email", setFieldTouched)}
                    onFocus={() => handleFocus("email")}
                    classes={{ root: classes.inputRoot }}
                    disableUnderline
                  />
                )}
              </Field>
              {errors.email && touched.email && (
                <FormHelperText error>{errors.email}</FormHelperText>
              )}
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel
                htmlFor="phone"
                className={activeField === "phone" ? classes.activeInputLabel : ""}
              >
                {t("profileSection.num")}
              </InputLabel>
              <Field name="phone">
                {({ field }: FieldProps) => (
                  <Input
                    {...field}
                    onBlur={() => handleBlur("phone", setFieldTouched)}
                    onFocus={() => handleFocus("phone")}
                    classes={{ root: classes.inputRoot }}
                    disableUnderline
                  />
                )}
              </Field>
              {errors.phone && touched.phone && (
                <FormHelperText error>{errors.phone}</FormHelperText>
              )}
            </FormControl>
            <Link
              className={classes.changePassword}
              component="button"
              onClick={handleChangePassword}
            >
              {t("profileSection.changePass")}
            </Link>
            {/* TODO: ADD disabled function */}
            <Link className={classes.logout} component="button" onClick={handleLogout}>
              <img src={logout} alt="logout" className={classes.logoutIcon} />
              Logout
            </Link>

            <Button className={classes.submitButton} type="submit">
              Save Changes
            </Button>
          </Form>
        )}
      </Formik>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={snackbarSeverity}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </MobileLayout>
  );
};

export default ProfilePage;

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  backButton: {
    color: "black",
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: "black",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  },
  profilePicture: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    margin: theme.spacing(2),
    // border: `4px solid ${theme.palette.background.paper}`,
    // cursor: 'pointer',
  },
  form: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "85%",
  },
  changePassword: {
    alignSelf: "flex-start",
    marginTop: theme.spacing(4),
    marginLeft: "10%",
    color: "#EB6608",
    fontSize: "1rem",
  },
  saveButton: {
    color: "#EB6608",
  },
  inputRoot: {
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "0 0 1px black",
    padding: "0 20px",
    height: "3rem",
  },
  activeInputLabel: {
    color: "#EB6608",
  },
  submitButton: {
    width: "85%",
    height: "3rem",
    position: "absolute",
    top: "80%",
    borderRadius: "10px",
    bottom: theme.spacing(6),
    backgroundColor: "#EB6608",
    color: "white",
    [theme.breakpoints.up("sm")]: {
      width: "25%",
      textTransform: "none",
    },
  },
  logout: {
    alignSelf: "flex-start",
    marginTop: theme.spacing(4),
    marginLeft: "10%",
    color: "#000",
    display: "flex",
    alignItems: "center",
    fontSize: "1rem",
  },
  logoutIcon: {
    width: "1rem",
    height: "1rem",
    marginRight: "0.5rem",
  },
}));
