import axios, { AxiosError } from 'axios';
import { refreshAccessToken } from '../lib/token/tokenService';

export async function getBreefById(breefId: any) {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/v1/Breefs/${breefId}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

interface GetPendingBreefsParams {
  userId: string;
}

export const getPendingBreefs = async ({ userId }: GetPendingBreefsParams) => {
  const accessToken = localStorage.getItem('accessToken');

  if (!accessToken) {
    throw new Error('Access token not found');
  }

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/v1/Breefs/breefs/user/${userId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || 'Failed to fetch pending breefs');
    } else if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error('An unknown error occurred');
    }
  }
};

export const getSubmittedBreefs = async ({ userId }: GetPendingBreefsParams) => {
  const accessToken = localStorage.getItem('accessToken');

  if (!accessToken) {
    throw new Error('Access token not found');
  }

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/v1/breefs/completed/${userId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response?.status === 401) {
        // Token refresh logic
        try {
          const newAccessToken = await refreshAccessToken();
          // Retry the original request with the new access token
          const retryResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/v1/breefs/completed/${userId}`, {
            headers: {
              Authorization: `Bearer ${newAccessToken}`,
            },
          });
          return retryResponse.data;
        } catch (refreshError) {
          throw new Error('Failed to refresh access token');
        }
      }

      // Handle other errors
      throw new Error( 'Failed to fetch submitted breefs');
    } else if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error('An unknown error occurred');
    }
  }
  
};