import { useEffect, useState } from "react";
import MobileLayout from "../MobileLayout";
import Logo from "../asset/logo/LOGO_ORANGE.svg";
import blueAlien from "../asset/characters/BlueAlien.png";
import introductionAlien from "../asset/characters/introductionAlien.png";
import { AppBar, Box, makeStyles, Toolbar, Typography } from "@material-ui/core";
import { useNavigate, useLocation } from "react-router";
import breeferButton from "../asset/icons/breeferPlayButton.png";
import { authenticateBreefAccessLink, getBreefReport } from "../lib/token/authenticateService";
import { getBreefById } from "./BreefService";
import { useDispatch } from "react-redux";
import { BreefDetails, setBreefDetails } from "../game/slice";
import { setNewUser } from "../login/slice";
import { useTranslation } from "react-i18next";
import "../App.css";
import mixpanel from "../mixpanel/mixpanelWrapper";

// import moment from 'moment-timezone';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
}));
export const Introduction = () => {
  const classes = useStyles();
  const [t, i18n] = useTranslation("global");
  sessionStorage.setItem("pageReloaded", "false");
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("");
  const [userDetail, setUserDetail] = useState<object>();
  const [breefDetail, setBreefDetail] = useState<BreefDetails>();
  const [prize, setPrize] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isTracked, setIsTracked] = useState(false);

  let breefReport: any;
  let authResponse: any;
  let userId: string;

  useEffect(() => {
    const authenticate = async () => {
      const token = new URLSearchParams(location?.search).get("token");
      console.log("TOKEN: ", token);

      if (token) {
        try {
          authResponse = await authenticateBreefAccessLink(token);
          const breefId = authResponse?.breefDetails.breefId;
          userId = authResponse.userDetails?._id;
          console.log("RESP: ", authResponse);
          const userDetails = authResponse.userDetails;
          // const dateParts = dateString.split("/");
          // const formattedDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
          // formattedDate.setHours(0, 0, 0, 0);
          // const date = new Date()

          localStorage.setItem("firstName", userDetails.firstName);
          localStorage.setItem("lastName", userDetails.lastName);
          localStorage.setItem("email", userDetails.email);
          localStorage.setItem("phone", userDetails.phoneNumber);
          localStorage.setItem("tenantId", userDetails.tenantId);
          localStorage.setItem("BreefAccesstoken", token);
          localStorage.setItem("teamId", userDetails.teamIds);
          setUserDetail(userDetails);
          
          const breefDet = authResponse.breefDetails;
          localStorage.setItem("breefId", breefDet.breefId);
          localStorage.setItem("videoUrl", breefDet.videoUrl);
          setPrize(breefDet.prize);
         

          const breefDetails = await getBreefById(breefId);
          setBreefDetail(breefDetails);
          dispatch(setBreefDetails(breefDetails));
          mixpanel.track("Inroduction Page Viewed", {
            breef_id: breefDetails.breefId,
            tenant_id: userDetails.tenantId,
            user_id: userId,
            number_of_qustions: breefDetails.questions.length,
            start_date: breefDetails.period,
            expiry_date: breefDetails.expiry,
            video_url: breefDetails.videoUrl,
            current_page: "intro",
            game_name: breefDetails.gameName,
          });
          console.log(userDetails._id);
          if (userDetails._id) {
            localStorage.setItem("InvitedUserId", userDetails._id);
          }
          setUserName(userDetails.firstName);
        } catch (error: any) {
          console.error("Failed to authenticate token:", error);
          if (authResponse?.message !== "Authenticated") {
            navigate("/no-access");
          }
          setError("Failed to authenticate. Please try again.");
        }
      } else {
        const { breef } = location.state;
        if (breef) {
          console.log("Breef from state:", breef);
          dispatch(setBreefDetails(breef));
          setUserName(localStorage.getItem("firstName") || "Guest");
          setPrize(breef.prize);
        } else {
          setError("No token or breef state found.");
        }
      }
    };

    authenticate();
  }, [location]);
  const nextButtonHandle = () => {
    const token = new URLSearchParams(location.search).get("token");
    navigate("/video");
  };

  return (
    <MobileLayout>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "16%",
        }}
      >
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>{/* <img src={Logo} alt="logo" height="80 rem" /> */}</Toolbar>
        </AppBar>
        <div className="container">
          <div className="container">
            {t("introduction.hi")} {userName}.<div>{t("introduction.title1")} </div>
            <span
              onClick={() => {
                nextButtonHandle();
                mixpanel.track("Click here button clicked", {
                  breef_id: breefDetail?.id,
                  tenant_id: breefDetail?.tenantId.id,
                  user_id: userId,
                  number_of_qustions: breefDetail?.questions.length,
                  start_date: breefDetail?.period,
                  expiry_date: breefDetail?.expiry,
                  video_url: breefDetail?.videoUrl,
                  current_page: "intro",
                  game_name: breefDetail?.gameId.gameName,
                });
              }}
              style={{ color: "#FF6A20", cursor: "pointer", fontWeight: "bold" }}
            >
              {t("introduction.spanTxt")}{" "}
            </span>
            {t("introduction.title2")} {prize}.
          </div>
        </div>
      </div>

      <Box
        style={{
          position: "absolute",
          bottom: "7rem",
          left: "50%",
          transform: "translateX(-50%)",
          height: "100%",
        }}
      >
        {/* <img src={breeferButton} alt="Breefer Button" onClick={nextButtonHandle} style={{ cursor: 'pointer' }} /> */}
        <img
          src={introductionAlien}
          onClick={nextButtonHandle}
          alt="Blue Alien"
          style={{ position: "absolute", top: "90%", right: -150, width: "150px", height: "150px" }}
        />
      </Box>
    </MobileLayout>
  );
};
