import React from 'react';
import MobileLayout from '../MobileLayout';
import logo from '../asset/logo/LOGO_ORANGE.svg';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    cursor: 'pointer' // Adjust this as needed
  },
}));

const SplashScreen = () => {
    const navigate = useNavigate();
  const classes = useStyles();

  return (
    <MobileLayout>
      <div className={classes.container} onClick={()=>navigate('/sign-up')}>
        <img src={logo} alt="logo" />
      </div>
    </MobileLayout>
  );
};

export default SplashScreen;
