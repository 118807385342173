import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import OnboardReducer from "../login/slice";
import scoreSlice from "../game/slice";

const rootReducer = combineReducers({
  onboard: OnboardReducer,
  score: scoreSlice,
});
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const configureAppStore = (initialState = {}) => {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== "production",
  });
  return store;
};
export const store = configureAppStore();
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof configureAppStore>;
