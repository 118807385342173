import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IOnboardSlice {
    firstname: string,
    lastname: string
    email: string,
    phone: string,
    userID: string,
    newUser: boolean
}
  
const initialState: IOnboardSlice = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    userID: 'nan',
    newUser: false
};

export const onboardSlice = createSlice({
    name: 'onboard',
    initialState,
    reducers: {
        setFirstName: (state, action: PayloadAction<string>) => {
            state.firstname = action.payload
        },
        setLastName: (state, action: PayloadAction<string>) => {
            state.lastname = action.payload
        },
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload
        },
        setPhone: (state, action: PayloadAction<string>) => {
            state.phone = action.payload
        },
        setUserID: (state, action: PayloadAction<string>) => {
            state.userID = action.payload
        },
        setNewUser: (state, action: PayloadAction<boolean>) => {
            state.newUser = action.payload
        },
    },
});

export const { setFirstName, setLastName, setEmail, setPhone, setUserID, setNewUser } = onboardSlice.actions;

export default onboardSlice.reducer
