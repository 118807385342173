import { useState, useEffect } from "react";
import MobileLayout from "../MobileLayout";
import { AppBar, Box, Button, Toolbar, makeStyles } from "@material-ui/core";
import breeferLogo from "../asset/logo/LOGO_ORANGE.svg";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router";
import "./VideoScene.css";
import { useMediaQuery } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import breeferPlayButton from "../asset/icons/breeferPlayButton.png";
import breeferReplayButton from "../asset/icons/replay.png";
import breeferDisabledPlayButton from "../asset/icons/breeferDisabledPlayButton.png";
import { setDuration } from "../game/slice";
import { useTranslation } from "react-i18next";
import "../App.css";
import { RootState } from "../redux/store";
import mixpanel from "../mixpanel/mixpanelWrapper";

interface Question {
  question: string;
  options: string[];
  answer: number;
}
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  title: {
    flexGrow: 1,
    color: "black",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  },
  overlay: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
    cursor: "pointer",
  },
  hidden: {
    display: "none",
  },
}));

export const VideoScene = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const videoUrl: any = localStorage.getItem("videoUrl");
  const userId: any = localStorage.getItem("InvitedUserId");
  const tenantId: any = localStorage.getItem("tenantId");
  const [isVideoEnded, setIsVideoEnded] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isTracked, setIsTracked] = useState(false);
  const breefDetails = useSelector((state: RootState) => state.score.breefDetails);
  const questions: Question[] = breefDetails.questions;
  useEffect(() => {
    if (!isTracked) {
      mixpanel.track("Video Page Viewed", {
        breef_id: breefDetails.id,
        tenant_id: tenantId,
        user_id: userId,
        number_of_qustions: questions.length,
        start_date: "",
        expiry_date: breefDetails.expiry,
        video_url: videoUrl,
        current_page: "video",
        game_name: breefDetails.gameId.gameName,
      });
      setIsTracked(true);
    }
  }, [isTracked]);

  const handleVideoEnd = () => {
    setIsVideoEnded(true);
    setIsOverlayVisible(true);
    setIsPlaying(false);
  };
  const userName = localStorage.getItem("firstName");
  const handlePlayButtonClick = () => {
    console.log("clicked");
    navigate("/quiz-info");
  };
  const handleDuration = (duration: number) => {
    localStorage.setItem("duration", duration.toString());
    dispatch(setDuration(duration));
  };
  const handleOverlayClick = () => {
    setIsOverlayVisible(false);
    setIsPlaying(true);
    setIsVideoEnded(false);
  };

  return (
    <MobileLayout>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>{/* <img src={breeferLogo} alt="logo" height="80 rem" /> */}</Toolbar>
      </AppBar>
      <div style={{ marginBottom: "2.7%", display: "flex", justifyContent: "center" }}>
        <div className="video-container">
          <ReactPlayer
            className="react-player"
            style={{ backgroundColor: "black" }}
            url={videoUrl}
            width={"100%"}
            height={"64vh"}
            playing={isPlaying}
            loop={false}
            controls={true}
            onEnded={handleVideoEnd}
            onDuration={handleDuration}
            onPause={() => {
              setIsOverlayVisible(true);
              setIsPlaying(false);
            }}
            playsinline
          />
          {isOverlayVisible && !isPlaying && (
            <div className={classes.overlay}>
              <img
                src={isVideoEnded ? breeferReplayButton : breeferPlayButton}
                alt="Replay Button"
                style={{ cursor: "pointer" }}
                height={"120px"}
                onClick={()=>{handleOverlayClick();  
                   mixpanel.track(`${isPlaying ? "Play" : "Pause"} button clicked`, {
                  breef_id: breefDetails.id,
                  tenant_id: tenantId,
                  user_id: userId,
                  number_of_qustions: questions.length,
                  start_date: "",
                  expiry_date: breefDetails.expiry,
                  video_url: videoUrl,
                  current_page: "video",
                  game_name: breefDetails.gameId.gameName,
                })}}
              />
            </div>
          )}
        </div>
      </div>
      <div style={{ margin: "2rem 1rem" }}>
        <div style={{ fontSize: "2rem" }}>
          {isVideoEnded ? (
            <div
              className="container"
              style={{
                color: "#ff6a20",
                cursor: "pointer",
                zIndex: 3,
                textTransform: "none",
                lineHeight: "1.8rem",
                fontWeight: "500",
              }}
              onClick={()=>{handlePlayButtonClick();
                mixpanel.track("Video Next button clicked", {
                  breef_id: breefDetails.id,
                  tenant_id: tenantId,
                  user_id: userId,
                  number_of_qustions: questions.length,
                  start_date: "",
                  expiry_date: breefDetails.expiry,
                  video_url: videoUrl,
                  current_page: "video",
                  game_name: breefDetails.gameId.gameName,
                })
              }}
            >
              {t("video.clickHereToGoToQuiz")}
            </div>
          ) : (
            <div className="container" style={{ textAlign: "left", margin: "10% 13%" }}>
              <span style={{ color: "#ff6a20", fontWeight: "900" }}>{t("video.title2")}</span>

              {t("video.title3")}
            </div>
          )}
        </div>
        {/* <div style={{ fontSize: "0.8rem", marginTop: "0.5rem", color: "#1C1B1F99", fontWeight: "700" }}>{t('video.txt')}</div> */}
      </div>
      {/* <Box style={{ position: 'absolute', bottom: '3rem', left: '50%', transform: 'translateX(-50%)' }}>
                {isVideoEnded ? (
                    <img src={breeferPlayButton} alt="Play Button" style={{ display: 'block', margin: '0 auto', cursor: 'pointer' }} onClick={handlePlayButtonClick} />
                ) : (
                    <img src={breeferDisabledPlayButton} alt="Disabled Play Button" style={{ display: 'block', margin: '0 auto' }} />
                )}
            </Box> */}
    </MobileLayout>
  );
};
