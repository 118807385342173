import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

interface ChangePasswordParams {
  token: string;
  newPassword: string;
}

export const resetPassword = async ({ token, newPassword }: ChangePasswordParams) => {
  const accessToken = localStorage.getItem('accessToken');

  if (!accessToken) {
    throw new Error('Access token not found');
  }

  try {
    const response = await axios.post(
      `${API_BASE_URL}/v1/auth/reset-password?token=${token}`,
      {
        password : newPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || 'Failed to change password');
    } else if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error('An unknown error occurred');
    }
  }
};


  export const changePassword = async ( newPassword : string) => {
    const refreshToken = localStorage.getItem('refreshToken');
    console.log("Refresh token: ", refreshToken);
    if (!refreshToken) {
      throw new Error('Refresh token not found');
    }
  
    try {
      const response = await axios.post(
        `${API_BASE_URL}/v1/auth/change-password`,
        {
          password: newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
            
          },
        }
      );
      return response.data;
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        throw new Error(error.response.data.message || 'Failed to change password');
      } else if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error('An unknown error occurred');
      }
    }
  };
  