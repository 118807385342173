import React, { ReactNode } from 'react';
import { useMediaQuery } from '@material-ui/core';
import background from './asset/background/background.png';

interface LayoutProps {
    children: ReactNode;
}

const MobileLayout = ({ children }: LayoutProps) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:1024px)');

    return (
        <div style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', /* Center content vertically */
            background: '#FFF5EE', 
            height: '100vh', 
            margin: 0, /* Ensure no default margins cause overflow */
            overflow: 'hidden' 
        }}>
            <div
                className="content"
                style={{
                    width: isMobile ? '100%' : (isTablet ? '50%' : '30%'),
                    backgroundImage: `url(${background})`,
                    backgroundSize: 'cover', // added
                    backgroundPosition: 'center', //added
                    height: '100vh',
                    overflowY: 'auto',//added
                    overflowX: 'hidden',//added
                    boxSizing: 'border-box'//added
                }}
            >
                {children}
            </div>
        </div>
    );
};

export default MobileLayout;
