import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  FormControl,
  Input,
  FormHelperText,
  Button,
  Link,
} from "@material-ui/core";
import { Form, ErrorMessage, Formik, Field, FieldProps } from "formik";
import * as Yup from "yup";
import MobileLayout from "../MobileLayout";
import { useLocation, useNavigate } from "react-router";
import logo from "../asset/logo/LOGO_ORANGE.svg";
import arrowRightWhite from "../asset/icons/rightarrow.png";
import { loginUser } from "./RegisterService";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import { setFirstName, setLastName, setEmail, setPhone, setUserID } from "./slice";
import { useDispatch } from "react-redux";
import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import mixpanel from "../mixpanel/mixpanelWrapper";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "transparent",
    boxShadow: "none",
    display: "flex",
    justifyContent: "center",
  },
  backButton: {
    color: "black",
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: "black",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  },
  formHeader: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(5),
    width: "100%",
    textAlign: "left",
  },
  form: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    paddingBottom: theme.spacing(10),
  },
  formControl: {
    margin: theme.spacing(1),
    width: "85%",
  },
  inputRoot: {
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "0 0 1px black",
    padding: "0 20px",
    height: "3rem",
  },
  submitButton: {
    width: "100%",
    backgroundColor: "#EB6608",
    color: "white",
    marginTop: theme.spacing(2),
    height: "3rem",
    borderRadius: "10px",
    fontSize: "1.0rem",
  },
  linkText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
    width: "100%",
  },
  forgotPasswordLink: {
    marginTop: theme.spacing(1),
    color: "black",
    cursor: "pointer",
    textAlign: "left",
    width: "80%",
  },
  signUpLink: {
    marginLeft: theme.spacing(1),
    color: "#EB6608",
    cursor: "pointer",
  },
  logo: {
    width: "auto",
    height: "10rem",
  },
  toolbar: {
    display: "flex",
    justifyContent: "center",
  },
  bottomContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(20),
    paddingBottom: theme.spacing(2),
    width: "85%",
  },
}));

const SigninPage = () => {
  const [t, i18n] = useTranslation("global");

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const classes = useStyles();
  const [activeField, setActiveField] = useState("");
  const [isTracked, setIsTracked] = useState(false);

  useEffect(() => {
    if (!isTracked) {
      mixpanel.track("Login Page Viewed", {
        current_page: "login",
      });
      setIsTracked(true);
    }
  }, [isTracked]);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().min(8, "Password must be at least 8 characters").required("Required"),
  });

  const handleFocus = (fieldName: string) => {
    setActiveField(fieldName);
  };

  const handleBlur = (
    fieldName: string,
    setFieldTouched: (fieldName: string, touched?: boolean, shouldValidate?: boolean) => void
  ) => {
    setFieldTouched(fieldName, true);
    setActiveField("");
  };

  const handleForgotPasswordClick = () => {
    navigate("/forgot-password");
  };

  const handleSignUpClick = () => {
    navigate("/sign-up");
  };
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("error");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleClose = (
    event: Event | React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  return (
    <MobileLayout>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <img src={logo} alt="logo" className={classes.logo} />
        </Toolbar>
      </AppBar>
      <Typography variant="body1" className={classes.formHeader}>
        {t("login.title")}
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          try {
            mixpanel.track("Login button clicked", {
              email: values.email,
              current_page: "login",
            });
            const user = await loginUser(values); // Call the loginUser function with form values
            mixpanel.alias(user.user.id);
            mixpanel.identify(user.user.id);
           
            dispatch(setFirstName(user.user.firstName));
            dispatch(setLastName(user.user.lastName));
            dispatch(setEmail(user.user.email));
            dispatch(setPhone(user.user.phoneNumber));
            dispatch(setUserID(user.user.id));

            navigate("/home");
            console.log("Form submitted", values);
          } catch (err: any) {
            console.log("Error registering the user: ", err);
            setErrorMessage(err.response.data.message);
            setSnackbarOpen(true);
          }
        }}
      >
        {({ errors, touched, handleChange, setFieldTouched }) => (
          <Form className={classes.form}>
            <FormControl className={classes.formControl}>
              <Field name="email">
                {({ field }: FieldProps) => (
                  <Input
                    {...field}
                    placeholder="name@host.com"
                    onBlur={() => handleBlur("email", setFieldTouched)}
                    onFocus={() => handleFocus("email")}
                    classes={{ root: classes.inputRoot }}
                    disableUnderline
                    autoComplete="off"
                  />
                )}
              </Field>
              {errors.email && touched.email && (
                <FormHelperText error>{errors.email}</FormHelperText>
              )}
            </FormControl>

            <FormControl className={classes.formControl}>
              <Field name="password" type="password">
                {({ field }: FieldProps) => (
                  <Input
                    {...field}
                    placeholder={t("login.pass")}
                    type="password"
                    onBlur={() => handleBlur("password", setFieldTouched)}
                    onFocus={() => handleFocus("password")}
                    classes={{ root: classes.inputRoot }}
                    disableUnderline
                    autoComplete="off"
                  />
                )}
              </Field>

              {errors.password && touched.password && (
                <FormHelperText error>{errors.password}</FormHelperText>
              )}
            </FormControl>
            <Link className={classes.forgotPasswordLink} onClick={handleForgotPasswordClick}>
              {t("login.frgtPass")}
            </Link>
            <div className={classes.bottomContainer}>
              <div
                className={classes.linkText}
                style={{ justifyContent: "flex-start", alignItems: "center" }}
              >
                <Typography variant="body1">{t("login.acc")}</Typography>
                <Link className={classes.signUpLink} onClick={handleSignUpClick}>
                  {t("login.signup")}
                </Link>
              </div>
              <Button type="submit" variant="contained" className={classes.submitButton}>
                {t("login.signin")}
                <img
                  src={arrowRightWhite}
                  alt="arrow"
                  style={{ height: "12px", width: "12px", position: "absolute", right: "15px" }}
                />
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={snackbarSeverity}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </MobileLayout>
  );
};

export default SigninPage;
