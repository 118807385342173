import axios from 'axios';

export const authenticateBreefAccessLink = async (token: string) => {
  try {
    
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/v1/Breefs/authenticate-link?token=${token}`);
    console.log("what is the response",response.data);
    return response.data;
  }catch (error) {
    console.error('Error authenticating Breef access link:', error);
    throw error;
  }
};

export const getBreefReport = async (userId: string) => {
  try {
    
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/v1/Breefs/breef-report/user/${userId}`);
    console.log("what is the response",response.data);
    return response.data;
  }catch (error) {
    console.error('Error fetching Breef Report:', error);
    throw error;
  }
};