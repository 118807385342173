import React from "react";
import medal from "../asset/home-section/Medal.png";
import orange from "../asset/home-section/orange.png";
import { useTranslation } from "react-i18next";

interface CardProps {
  rank: string;
  perc: string;
}

const Card: React.FC<CardProps> = ({ rank, perc }) => {
  const [t, i18n] = useTranslation("global");
  return (
    <div className="ranking-card">
      <div style={{ width: "60%" }}>
        <div className="card-header">
          <div className="img-1">
            <img src={medal} alt="" />
          </div>
          <div className="card-title">{t("card.rank")}</div>
          <div className="card-number">{rank}</div>
        </div>
        <div className="card-heading">
        {t("card.title1")} {perc}{t("card.title2")}
        </div>
      </div>
      <div className="right-side">
        <img src={orange} alt="" />
      </div>
      <div className="card-background"></div>
    </div>
  );
};

export default Card;
