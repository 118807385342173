import React, { useState } from 'react';
import MobileLayout from '../MobileLayout';
import { getPendingBreefs } from '../introduction/BreefService';
import { HomeCard } from './HomeCard';
import { useNavigate } from 'react-router';
import frame2 from "../asset/home-section/frame2.png";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useTranslation } from 'react-i18next';

interface PendingBreef {
    breefName: string;
    expiry: string;
    gameId: {
        gameName: string;
        categoryId: string;
        gameCode: string;
        id: string;
    };
    id: string;
    questions: any[];
    status: string;
    tenantId: {
        status: string;
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
    };
    videoUrl: string;
}

const View = () => {
    const [t, i18n] = useTranslation("global");
    const [pendingBreefs, setPendingBreefs] = useState<PendingBreef[]>([]);
    const [submittedBreefs, setSubmittedBreefs] = useState<PendingBreef[]>([]);
    const userId = localStorage.getItem('userId') || '';

    React.useEffect(() => {
        const fetchBreefs = async () => {
            try {
                const breefs = await getPendingBreefs({ userId });
                setPendingBreefs(breefs.pendingBreefs);
                setSubmittedBreefs(breefs.submittedBreefs);
                console.log('Breefs:', breefs);
            } catch (error: any) {
                console.error('Failed to fetch breefs details:', error.message);
            }
        };
        fetchBreefs();
    }, [userId]);

    const navigate = useNavigate();

    // Inline styles with corrected types
    const containerStyle: React.CSSProperties = {
        display: 'flex',
        flexWrap: 'wrap', // Ensure this is a valid CSS value
        gap: '16px', // Adjust the gap between cards if needed
        justifyContent: 'start',
        marginTop: "8px"
    };

    const cardStyle: React.CSSProperties = {
        flex: '1 1 calc(50% - 16px)', // 50% width minus the gap
        boxSizing: 'border-box',
        // marginBottom: '4px' // Add margin at the bottom if needed
    };

    // Navbar style
    const navbarStyle: React.CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 16px',
        backgroundColor: '#f8f9fa', // Adjust background color as needed
        borderBottom: '1px solid #e9ecef' // Optional border
    };

    const backButtonStyle: React.CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    };

    const backIconStyle: React.CSSProperties = {
        marginRight: '8px'
    };

    return (
        <MobileLayout>
            <div style={navbarStyle}>
                <div
                    style={backButtonStyle}
                    onClick={() => navigate(-1)} // Go back to the previous page
                >
                    <IoIosArrowRoundBack color='black'/>
                    <span>{t('view.back')}</span>
                </div>
            </div>
            <div style={containerStyle}>
                {pendingBreefs && pendingBreefs.map(breef => (
                    <div key={breef.id} style={cardStyle}>
                        <HomeCard
                            image={frame2} 
                            title={breef.breefName}
                            onClick={() => { navigate('/breefInvite', { state: { breef } }) }}
                        />
                    </div>
                ))}
            </div>
        </MobileLayout>
    );
};

export default View;
