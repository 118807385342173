import React, { useState, useEffect } from "react";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Box,
  Button,
  useMediaQuery,
} from "@material-ui/core";
import { Form, Formik, Field, FieldProps, FormikHelpers } from "formik";
import * as Yup from "yup";
import { KeyboardArrowLeft } from "@material-ui/icons";
import MobileLayout from "../MobileLayout";
import { ThemeProvider } from "@emotion/react";
import { useNavigate, useLocation } from "react-router";
import { changePassword } from "./ChangePasswordService";
import arrowWhite from "../asset/icons/rightarrow.png";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import mixpanel from "../mixpanel/mixpanelWrapper";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  backButton: {
    color: "black",
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: "black",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  },
  form: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "85%",
  },
  inputRoot: {
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "0 0 1px black",
    height: "3rem",
    padding: "0 20px",
  },
  errorMessage: {
    color: "red",
    marginTop: theme.spacing(1),
  },
}));

const ChangePasswordPage = () => {
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isTracked, setIsTracked] = useState(false);

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const [errorMessage, setErrorMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("error");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    if (!isTracked) {
      mixpanel.track("Change password viewed", {
        current_page: "change-password",
      });
      setIsTracked(true);
    }
  }, [isTracked]);

  const handleClose = (
    event: Event | React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required("Required"),
    newPassword: Yup.string().required("Required").min(8, t("changePass.passTxt1")),
    confirmPassword: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("newPassword")], t("changePass.passTxt2")),
  });

  const handleSubmit = async (
    values: typeof initialValues,
    { setSubmitting, setErrors }: FormikHelpers<typeof initialValues>
  ) => {
    try {
      mixpanel.track("Update password clicked", {
        current_page: "change-password",
      });
      await changePassword(values.newPassword);
      navigate("/password-changed");
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrors({ currentPassword: error.message + "- Sagnik" });
        setErrorMessage(error.message);
        setSnackbarOpen(true);
      } else {
        setErrors({ currentPassword: "An unknown error occurred" });
        setErrorMessage("An unknown error occurred");
        setSnackbarOpen(true);
      }
      setSubmitting(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <MobileLayout>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              aria-label="back"
              className={classes.backButton}
              onClick={() => navigate(-1)}
            >
              <KeyboardArrowLeft />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t("changePass.changePassw")}
            </Typography>
          </Toolbar>
        </AppBar>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit} // Ensure this is correct
        >
          {({ errors, touched, isSubmitting }) => (
            <Form className={classes.form}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="currentPassword" shrink>
                  {t("changePass.currPass")}
                </InputLabel>
                <Field name="currentPassword">
                  {({ field }: FieldProps) => (
                    <Input
                      {...field}
                      type="password"
                      classes={{ root: classes.inputRoot }}
                      disableUnderline
                    />
                  )}
                </Field>
                {errors.currentPassword && touched.currentPassword && (
                  <FormHelperText error>{errors.currentPassword}</FormHelperText>
                )}
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="newPassword" shrink>
                  {t("changePass.newPass")}
                </InputLabel>
                <Field name="newPassword">
                  {({ field }: FieldProps) => (
                    <Input
                      {...field}
                      type="password"
                      classes={{ root: classes.inputRoot }}
                      disableUnderline
                    />
                  )}
                </Field>
                {errors.newPassword && touched.newPassword && (
                  <FormHelperText error>{errors.newPassword}</FormHelperText>
                )}
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="confirmPassword" shrink>
                  {t("changePass.confirmPass")}
                </InputLabel>
                <Field name="confirmPassword">
                  {({ field }: FieldProps) => (
                    <Input
                      {...field}
                      type="password"
                      classes={{ root: classes.inputRoot }}
                      disableUnderline
                    />
                  )}
                </Field>
                {errors.confirmPassword && touched.confirmPassword && (
                  <FormHelperText error>{errors.confirmPassword}</FormHelperText>
                )}
              </FormControl>
              {errors.currentPassword && !touched.currentPassword && (
                <Typography className={classes.errorMessage}>{errors.currentPassword}</Typography>
              )}
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: theme.spacing(2),
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    backgroundColor: "#EB6608",
                    color: "#FFFFFF",
                    width: "85%",
                    maxWidth: "500px",
                    textTransform: "none",
                    height: "3rem",
                    borderRadius: "10px",
                  }}
                  disabled={isSubmitting}
                >
                  {t("changePass.changePassw")}
                  <img
                    src={arrowWhite}
                    alt="arrowWhite"
                    style={{ height: "12px", width: "12px", position: "absolute", right: "15px" }}
                  />
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </MobileLayout>
    </ThemeProvider>
  );
};

export default ChangePasswordPage;
