import React from 'react';

interface Props {
    image: string;
    title: string;
    onClick?: () => void; // Make onClick optional
}

export const HomeCard: React.FC<Props> = (props) => {
  const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column' as 'column', // Ensure correct type
    justifyContent: 'center' as 'center', // Ensure correct type
    alignItems: 'center' as 'center', // Ensure correct type
  };

  return (
    <div onClick={props.onClick} style={containerStyle}>
      <div>
        <img src={props.image} alt={props.title} style={{ maxWidth: '100%', height: 'auto' }} />
      </div>
      <div style={{ textAlign: 'center', marginTop: '8px' }}>{props.title}</div>
    </div>
  );
};
