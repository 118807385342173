import axios from 'axios';
import { refreshAccessToken } from '../lib/token/tokenService';

const API_URL = process.env.REACT_APP_API_BASE_URL;

export const getUserDetails = async () => {
  try {
    const userId = localStorage.getItem('userId');
    let accessToken = localStorage.getItem('accessToken');

    if (!userId || !accessToken) {
      throw new Error('User ID or access token not found in local storage');
    }

    const response = await axios.get(`${API_URL}/v1/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error : any) {
    if (error.response && error.response.status === 401) {
      
      try {
        const newAccessToken = await refreshAccessToken();
        const userId = localStorage.getItem('userId');

        if (!userId) {
          throw new Error('User ID not found in local storage');
        }

        const response = await axios.get(`${API_URL}/v1/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${newAccessToken}`,
          },
        });

        return response;
      } catch (refreshError) {
        console.error('Error fetching user details after refreshing token', refreshError);
        throw refreshError;
      }
    } else {
      console.error('Error fetching user details', error);
      throw error;
    }
  }
};


export const updateUserDetails = async (payload: any) => {
  try {
    const userId = localStorage.getItem('userId');
    const accessToken = localStorage.getItem('accessToken');
    
    // Log the retrieved values
    console.log('userId:', userId);
    console.log('accessToken:', accessToken);

    if (!userId || !accessToken) {
      throw new Error('User ID or access token not found in local storage');
    }

    const response = await axios.patch(
      `${API_URL}/v1/users/${userId}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
    
  } catch (error: any) {
    if (error.response) {
      console.error('Error response data:', error.response.data);
      console.error('Error response status:', error.response.status);
      console.error('Error response headers:', error.response.headers);
    } else if (error.request) {
      console.error('Error request data:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
    console.error('Error config:', error.config);
    throw error;
  }
};