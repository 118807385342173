import React from 'react';
import MobileLayout from '../MobileLayout';
import OrangeAlien from '../asset/characters/OrangeAlien.png'; // Assuming correct path to OrangeAlien image
import { Box, Button, Typography, useMediaQuery, ThemeProvider, createTheme } from '@material-ui/core';
import arrowWhite from '../asset/icons/rightarrow.png';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
const PasswordChangedPage = () => {
  const theme = createTheme(); // Create a default theme
   const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [t, i18n] = useTranslation("global");
  return (
    <ThemeProvider theme={theme}>
      <MobileLayout>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box justifyContent="center" mt={"10%"} width={'100%'}>
            <img src={OrangeAlien} alt="Orange Alien" style={{ width: '250px', height: '250px', display: 'block', margin: isMobile ? '0 auto' : '0 auto' }} />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
          
          <Typography variant="h4"  align="center" style={{ marginTop: '10%' }}>
          {t('confirmPass.title')}
          </Typography>
          <Box style={{ width: isMobile ? '80%' : '25%', position: 'absolute', bottom: '5rem', left: '50%', transform: 'translateX(-50%)' }}>
            <Button variant="contained" style={{ backgroundColor: '#EB6608', color: '#FFFFFF', width: '100%', textTransform: 'none', height: '3rem', borderRadius: '10px' }} onClick={() => navigate('/profile') }>
              {t('confirmPass.back')}
              <img src={arrowWhite} alt="arrowWhite" style={{ height: '12px', width: '12px', position: 'absolute', right: '15px' }} />
            </Button>
          </Box>
        </Box>
      </MobileLayout>
    </ThemeProvider>
  );
};

export default PasswordChangedPage;
