import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BreefDetails {
    id: string;
    breefName: string;
    videoUrl: string;
    questions: {
      question: string;
      options: string[];
      answer: number;
    }[];
    tenantId: {
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber: string;
      name: string;
      role: string;
      isEmailVerified: boolean;
    };
    gameId: {
      id: string;
      gameName: string;
      categoryId: string;
      gameCode: string;
      status: string;
      gameDescription: string;
    };
    expiry: string;
    period:string;
}

export const initialBreefDetails: BreefDetails = {
    id: '',
    breefName: '',
    videoUrl: '',
    questions: [],
    tenantId: {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      name: '',
      role: '',
      isEmailVerified: false
    },
    gameId: {
      id: '',
      gameName: '',
      categoryId: '',
      gameCode: '',
      status: '',
      gameDescription: ''
    },
    expiry: '',
    period:'',
  };
  

export interface Score {
    points: number,
    quizScore: number,
    duration: number,
    quizChances: { index: number; number: number }[];
    breefDetails: BreefDetails
}
  
const initialState: Score = {
    points: 0,
    quizScore: 0,
    duration: 0,
    quizChances: [],
    breefDetails: initialBreefDetails
};

export const scoreSlice = createSlice({
    name: 'score',
    initialState,
    reducers: {
        setScore: (state, action: PayloadAction<number>) => {
            state.points = action.payload
        },
        setQuizScore: (state, action: PayloadAction<number>) => {
            state.quizScore = action.payload
        },
        setDuration: (state, action: PayloadAction<number>) => {
            state.duration = action.payload
        },
        setBreefDetails: (state, action: PayloadAction<BreefDetails>) => {
            state.breefDetails = action.payload
        },
        setQuizChance: (state, action: PayloadAction<number>) => {
          const index = state.quizChances.length;
          state.quizChances.push({ index, number: action.payload });
      }
    }
});

export const { setScore, setQuizScore, setDuration, setBreefDetails, setQuizChance } = scoreSlice.actions;

export default scoreSlice.reducer
