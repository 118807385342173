import React from 'react';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Box,
  Button,
  useMediaQuery,
} from '@material-ui/core';
import { Form,Formik, Field, FieldProps, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { KeyboardArrowLeft } from '@material-ui/icons';
import MobileLayout from '../MobileLayout';
import { ThemeProvider } from '@emotion/react';
import { useNavigate, useLocation } from 'react-router';
import {resetPassword} from './ChangePasswordService';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  backButton: {
    color: 'black',
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: 'black',
    textAlign: 'center',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  form: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '80%',
  },
  inputRoot: {
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0 0 1px black',
    padding: '0 10px',
  },
  errorMessage: {
    color: 'red',
    marginTop: theme.spacing(1),
  },
}));

const ResetPasswordPage = () => {
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Required'),
    newPassword: Yup.string().required('Required').min(8, t('resetPass.passTxt1')),
    confirmPassword: Yup.string()
      .required('Required')
      .oneOf([Yup.ref('newPassword')], t('resetPass.passTxt2')),
  });

  const handleSubmit = async (
    values: typeof initialValues,
    { setSubmitting, setErrors }: FormikHelpers<typeof initialValues>
  ) => {
    const params = new URLSearchParams(location.search);
    let token = params.get('token');

   
      if (!token) {
        setErrors({ currentPassword:  t('resetPass.err')});
        setSubmitting(false);
        return;
      }
    
      console.log("Token: ", token);
      
    try {
      await resetPassword({ token, newPassword: values.newPassword });
      navigate('/password-changed');
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrors({ currentPassword: error.message });
      } else {
        setErrors({ currentPassword: 'An unknown error occurred' });
      }
      setSubmitting(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <MobileLayout>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" aria-label="back" className={classes.backButton} onClick={() => navigate(-1)}>
              <KeyboardArrowLeft />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('resetPass.reset')}
            </Typography>
          </Toolbar>
        </AppBar>
        
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, handleSubmit, isSubmitting }) => (
            <form className={classes.form} onSubmit={handleSubmit}>
              
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="newPassword" shrink>
                  {t('resetPass.newPass')}
                </InputLabel>
                <Field name="newPassword">
                  {({ field }: FieldProps) => (
                    <Input
                      {...field}
                      type="password"
                      classes={{ root: classes.inputRoot }}
                      disableUnderline
                    />
                  )}
                </Field>
                {errors.newPassword && touched.newPassword && <FormHelperText error>{errors.newPassword}</FormHelperText>}
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="confirmPassword" shrink>
                {t('resetPass.confNewPass')}
                </InputLabel>
                <Field name="confirmPassword">
                  {({ field }: FieldProps) => (
                    <Input
                      {...field}
                      type="password"
                      classes={{ root: classes.inputRoot }}
                      disableUnderline
                    />
                  )}
                </Field>
                {errors.confirmPassword && touched.confirmPassword && <FormHelperText error>{errors.confirmPassword}</FormHelperText>}
              </FormControl>
              {errors.currentPassword && !touched.currentPassword && (
                <Typography className={classes.errorMessage}>{errors.currentPassword}</Typography>
              )}
              <Box style={{  display: 'flex', justifyContent: 'center', width: '100%', marginTop: theme.spacing(2) }}>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#EB6608', color: '#FFFFFF', width: '80%', maxWidth: '500px', textTransform: 'none' }}
                  disabled={isSubmitting}
                >
                  {t('resetPass.reset')}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </MobileLayout>
    </ThemeProvider>
  );
};

export default ResetPasswordPage;